.swiper-scrollbar {
    padding-right: var(--spacing-size-nine);
    margin-left: var(--spacing-size-nine);
}

.scrollbar-swiper .swiper-horizontal > .swiper-scrollbar,
.scrollbar-swiper .swiper-scrollbar.swiper-scrollbar-horizontal {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    width: unset;
    margin-left: var(--spacing-size-nine);
    margin-right: var(--spacing-size-nine);
}

.swiper-scrollbar-drag {
    background-color: var(--color-brand-black);
    border-radius: 0;
    right: 0;
}

.swiper-scrollbar {
    background-color: var(--color-brand-aluminium);
    border-radius: 0;
}

.swiper-button {
    display: block;
    z-index: 10;
    cursor: pointer;
}

.swiper-icon-disabled {
    opacity: 0.5;
    pointer-events: none;
}

/* Vertical swiper gets special styles */
.swiper-vertical {
    overflow-y: hidden;
    overflow-y: clip;
    overflow-x: unset;
    /* max-width: 44.375rem; */
    height: 100%;
}

.swiper-vertical .swiper-scrollbar {
    position: absolute;
    left: var(--swiper-scrollbar-left, auto);
    right: var(--swiper-scrollbar-right, -18px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    width: var(--swiper-scrollbar-size, 1px);
    height: 50%;
    padding: unset;
    margin: unset;
}

.swiper-vertical .swiper-scrollbar-drag {
    width: 100%;
    position: relative;
    background-color: var(--color-brand-black);
}

.scrollbar-swiper .swiper-wrapper {
    padding-bottom: var(--spacing-size-seven);
}

.swiperContainer {
    padding-top: var(--spacing-size-seven);
}

.swiperContainer .swiper {
    z-index: 0;
}

.productSKUFor3 {
    width: 80%;
    margin: auto;
}

.productSKUFor2 {
    width: 22.5%;
    margin: auto;
}

.productSKUFor3 .swiper-slide {
    flex-shrink: unset !important;
}

.productSKUFor2 .swiper-slide {
    flex-shrink: unset !important;
}

.nostoFlatlayBeforeGrid .swiperContainer {
    padding-top: var(--spacing-size-three);
}

.nostoFlatlayAltBeforeGrid .scrollbar-swiper .swiper-wrapper {
    padding-bottom: var(--spacing-size-three);
}

.nostoFlatlayAltBeforeGrid .swiperContainer {
    padding-top: 0;
}

.lookbookDrawer .swiperContainer {
    padding-top: var(--spacing-size-five);
}

.lookbookDrawer .swiperContainer {
    padding-bottom: var(--spacing-size-five);
}

.lookbookDrawer .scrollbar-swiper .swiper-wrapper {
    padding-bottom: 0;
}

[class*='swiper-icon-'] {
    padding: var(--spacing-size-three) var(--spacing-size-four);
    border-radius: var(--border-radius-lg);
    transition: background-color 200ms ease;
}

[class*='swiper-icon-']:hover {
    background-color: var(--surface-hover-invert);
}

.productNostoRecCard .swiperContainer {
    padding-top: var(--spacing-size-four);
}

.productNostoRecCard .swiper-scrollbar {
    display: none;
}

.productNostoRecCard .scrollbar-swiper .swiper-wrapper {
    padding-bottom: 0;
}

@media screen and (max-width: 991px) {
    .swiper-scrollbar {
        display: none;
    }

    .swiper-pdp-gallery .swiper-scrollbar {
        display: block;
    }

    .scrollbar-swiper .swiper-wrapper {
        padding-bottom: 0 !important;
    }

    .productSKUFor2 {
        width: 100%;
    }

    .productSKUFor3 {
        width: 100%;
    }

    .swiperContainer {
        padding-top: var(--spacing-size-six);
    }

    .productSKUFor2 .swiperContainer {
        padding-top: 0;
    }

    .productSKUFor2 .swiper-slide {
        flex-shrink: 0;
    }

    .productSKUFor3 .swiper-slide {
        flex-shrink: 0;
    }

    .overlayContent .swiperContainer {
        padding-top: var(--spacing-size-four);
    }

    .productNostoRecCard .swiperContainer {
        padding-top: var(--spacing-size-three);
    }
}

@media screen and (max-width: 767px) {
    /* Scrollbar on horizontal pdp swiper gets special styles */
    .swiper-pdp-gallery .swiper-scrollbar {
        --swiper-scrollbar-sides-offset: var(--spacing-size-five);
        --swiper-scrollbar-size: 1px;
        display: block;
        position: relative;
        bottom: 0;
        padding: 0;
        margin: 0;
    }

    .productSKUFor2 .swiper-slide {
        flex-shrink: 0;
    }

    .productSKUFor3 .swiper-slide {
        flex-shrink: 0;
    }
}
